export const FORMAT_DATE_TIME_FULL = "dddd, MMMM D, YYYY h:mm A";
export const FORMAT_DATE_SHORT = "YYYY-MM-DD";
export const FORMAT_DATE_TIME_SHORT = "YYYY-MM-DD hh:mm:ss";

export const CLUSTER_STATE = {
  DELETED: "DELETED",
  DELETING: "DELETING",
  FAILED: "FAILED",
  MODIFYING: "MODIFYING",
  PROVISIONED: "PROVISIONED",
  PROVISIONING: "PROVISIONING",
  SCHEDULE_DELETION: "SCHEDULE_DELETION",
};

export const SUB_DOMAIN_REGEX = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/m;

// keep it in sync with its counterpart in /common/../CommonUtils.java
export const MAX_CLUSTER_NAME_SIZE = 18;

export const CLUSTER_ACTION_TYPE = {
  ADD_EKS_ADMIN: "ADD_EKS_ADMIN",
  GET_DEPLOYMENTS_INFO: "GET_DEPLOYMENTS_INFO",
  GET_PODS_NAMES: "GET_PODS_NAMES",
  GET_PODS_DATA: "GET_PODS_DATA",
  HEALTH_CHECK: "HEALTH_CHECK",
  PULL_LOGS: "PULL_LOGS",
  DELETE_POD: "DELETE_POD",
  ROLLOUT_RESTART: "ROLLOUT_RESTART",
  UPGRADE_DEPLOYMENT: "UPGRADE_DEPLOYMENT",
  SCALE_DEPLOYMENT: "SCALE_DEPLOYMENT",
  ROLLBACK_DEPLOYMENT: "ROLLBACK_DEPLOYMENT",
  CHANGE_ENVIRONMENT_TYPE: "CHANGE_ENVIRONMENT_TYPE",
  DEPLOY_METRICS_PROXY: "DEPLOY_METRICS_PROXY",
  CHANGE_TELEMETRY_STATE: "CHANGE_TELEMETRY_STATE",
  DEPLOY_DEBUGGER_POD: "DEPLOY_DEBUGGER_POD",
  UPLOAD_CERTIFICATE: "UPLOAD_CERTIFICATE",
  DOWNLOAD_ALL_POD_LOGS: "DOWNLOAD_ALL_POD_LOGS",
  DOWNLOAD_THREAD_DUMP: "DOWNLOAD_THREAD_DUMP",
  DOWNLOAD_HEAP_DUMP: "DOWNLOAD_HEAP_DUMP",
  GET_TOP_OUTPUT_FROM_POD: "GET_TOP_OUTPUT_FROM_POD",
  RUN_SQL_QUERY_IN_CONDUCTOR: "SQL_CONDUCTOR",
  KUBECTL_UNRESTRICTED: "KUBECTL_UNRESTRICTED",
  GET_INFRASTRUCTURE_METRICS: "GET_INFRASTRUCTURE_METRICS",
};

export const URL_REGEX =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const CAPITALIZED_CHARS_REGEX = /(?=[A-Z])/;

export const LINEAR_PROGRESS_INDEX = 1999;

// footer links on login page

export const BASE = "https://orkes.io";
export const PLATFORM_URL = `${BASE}/platform`;
export const COMMUNITY_URL = `${BASE}/community`;
export const GET_STARTED_URL = "https://orkes.io/get-started";
export const DOCS_URL = `${BASE}/content`;
export const CUSTOMERS_URL = `${BASE}/customers`;
export const EVENTS_URL = `${BASE}/events`;
export const BLOGS_URL = `${BASE}/blog`;
export const PRIVACY_POLICY_URL = `${BASE}/privacy-policy`;
