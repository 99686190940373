declare global {
  interface Window {
    dataLayer: any[];
    lintrk?: (event: string, data: { conversion_id: number }) => void;
  }
}

export const postDataLayer = (eventName: string, userId: string) => {
  const dataLayer = (window.dataLayer = window.dataLayer || []);
  dataLayer.push({
    event: eventName,
    orkes_user_id: userId,
  });
};

export const sendLinkedInEmailConfirmationEvent = () => {
  if (typeof window !== "undefined" && window.lintrk) {
    window.lintrk("track", { conversion_id: 19206572 });
  } else {
    console.warn("LinkedIn tracking script not loaded");
  }
};
