import {
  AuthProviderContext,
  LoginWithPasswordEvent,
  SendMagicLinkEvent,
  SendResetPasswordEvent,
  SignInWithEmailLinkEvent,
  SignupWithPasswordEvent,
  SubmitNewPasswordEvent,
} from "./types";
import {
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
} from "firebase/auth";
import { fetchWithContext } from "utils/fetch";
import {
  verifyUserStatusPath,
  FETCH_CURRENT_USER_PROFILE,
  FETCH_BRAAVOS_BILLING_PROFILE,
  FETCH_AVAILABLE_USER_PROFILES,
  UPDATE_CURRENT_USER_PROFILE,
  FETCH_BRAAVOS_SUBSCRIPTION,
} from "utils/apiPaths";
import { defaultHeaders } from "utils/query";
import { auth } from "auth/AuthContext";
import {
  postDataLayer,
  sendLinkedInEmailConfirmationEvent,
} from "utils/postToDataLayer";

const INVITE_ID_IN_LOCAL_STORAGE = "inviteIdInLocalStorage";

const provider = new GoogleAuthProvider();

const isNewUserInFirebase = async (email: string): Promise<boolean> => {
  if (auth && email) {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      return signInMethods.length === 0;
    } catch (error: any) {
      console.error("Error checking if user is existing or new:", error);
      return false;
    }
  }
  return false;
};

export const acceptInvite = async ({
  inviteId,
  currentUser,
}: AuthProviderContext) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  const fetchParams = { headers: headers };

  const inviteIdInLocalStorage = localStorage.getItem(
    INVITE_ID_IN_LOCAL_STORAGE
  );
  const invitationIdToUse = inviteId || inviteIdInLocalStorage;

  if (invitationIdToUse) {
    try {
      const result = await fetchWithContext(
        `/users/acceptInvite/${invitationIdToUse}`,
        {},
        fetchParams
      );
      localStorage.removeItem(INVITE_ID_IN_LOCAL_STORAGE);
      return result;
    } catch (error: any) {
      localStorage.removeItem(INVITE_ID_IN_LOCAL_STORAGE);
      const errorBody = await error.json();
      return Promise.reject({
        message:
          errorBody.message ?? "Failed to accept the invite. Please try again.",
      });
    }
  }
};

export const fetchForCurrentOrganizations = async ({
  currentUser,
}: AuthProviderContext) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  const fetchParams = { headers: headers };
  try {
    const result = await fetchWithContext(
      `/currentOrganization/`,
      {},
      fetchParams
    );
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message ?? "Failed to fetch organization",
    });
  }
};

export const fetchForAvailableProfiles = async ({
  currentUser,
}: AuthProviderContext) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  const fetchParams = { headers: headers };
  try {
    const result = await fetchWithContext(
      FETCH_AVAILABLE_USER_PROFILES,
      {},
      fetchParams
    );
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message ?? "Failed fetching available profiles",
    });
  }
};

export const fetchForCurrentProfile = async ({
  currentUser,
}: AuthProviderContext) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  const fetchParams = { headers: headers };
  try {
    const result = await fetchWithContext(
      FETCH_CURRENT_USER_PROFILE,
      {},
      fetchParams
    );
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message ?? "Failed fetching current profile",
    });
  }
};

export const fetchForProfileInfo = async (context: AuthProviderContext) => {
  const availableProfiles = await fetchForAvailableProfiles(context);
  const currentProfile = await fetchForCurrentProfile(context);

  return {
    availableProfiles: availableProfiles.available_user_profiles,
    currentProfile: currentProfile.current_user_profile,
  };
};

const fetchForBillingProfile = async ({ currentUser }: AuthProviderContext) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  try {
    const result = await fetchWithContext(
      FETCH_BRAAVOS_BILLING_PROFILE,
      {},
      { headers: headers }
    );
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message ?? "Failed to fetch billing profile",
    });
  }
};

const fetchForBraavosSubscription = async ({
  currentUser,
}: AuthProviderContext) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  try {
    const result = await fetchWithContext(
      FETCH_BRAAVOS_SUBSCRIPTION,
      {},
      { headers: headers }
    );
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message ?? "Failed to fetch subscription",
    });
  }
};

export const fetchBillingProfileAndSubscription = async ({
  currentUser,
}: AuthProviderContext) => {
  try {
    const [billingProfile, braavosSubscription] = await Promise.all([
      fetchForBillingProfile({ currentUser }),
      fetchForBraavosSubscription({ currentUser }),
    ]);
    return { billingProfile, braavosSubscription };
  } catch (error: any) {
    throw new Error(
      error.message ?? "Failed to fetch billing profile and subscription"
    );
  }
};

export const switchCurrentProfile = async (
  { currentUser }: AuthProviderContext,
  event: { data: { profile: string } }
) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  try {
    const result = await fetchWithContext(
      `${UPDATE_CURRENT_USER_PROFILE}?userProfile=${event.data.profile}`,
      {},
      { headers: headers, method: "POST" }
    );
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message ?? "Failed to fetch organization",
    });
  }
};

export const switchCurrentOrganization = async (
  { currentUser }: AuthProviderContext,
  data: { data: { id: string } }
) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  try {
    const result = await fetchWithContext(
      `/selectOrganization?id=${data.data.id}`,
      {},
      { headers: headers, method: "POST", body: JSON.stringify(data) }
    );
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message ?? "Failed to fetch organization",
    });
  }
};
/* NOT NEEDED HANDLED ELSEWHERE
export const assignTrialOrganization = async (
  { currentUser }: AuthProviderContext,
  data: AssignTrialOrganizationPayload
) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  try {
    const result = await fetchWithContext(
      `/assignTrialOrganization`,
      {},
      { headers: headers, method: "POST", body: JSON.stringify(data) }
    );
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message ?? "Failed to fetch organization",
    });
  }
};

export const assignTrialOrganizationAndPullOrganization = async (
  context: AuthProviderContext,
  data: AssignTrialOrganizationPayload
) => {
  const res = await assignTrialOrganization(context, data);
  console.log("Res is ", res);
  return await fetchForCurrentOrganizations(context);
};
*/

export const invokeLoginWithGoogle = async () => {
  if (auth) {
    try {
      const result = await signInWithPopup(auth, provider);
      const additionalInfo = getAdditionalUserInfo(result);
      if (result.user.email && additionalInfo?.isNewUser) {
        postDataLayer("free_trial_email_submitted", result.user.email);
        postDataLayer("free_trial_email_confirmed", result.user.email);
        sendLinkedInEmailConfirmationEvent();
      }
      return result.user;
    } catch (error: any) {
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log("error", error.code, error.message, error.email, credential);
      return Promise.reject({
        message: error.message ?? "Something went wrong. Please try again.",
      });
    }
  }
};

export const signupWithEmailPassword = async (
  { currentUser }: AuthProviderContext,
  { data }: SignupWithPasswordEvent
) => {
  const { email, password } = data;
  const headers = defaultHeaders(currentUser?.accessToken);
  const fetchParams = {
    headers: headers,
    method: "POST",
    body: JSON.stringify({
      email,
      password,
    }),
  };

  try {
    localStorage.setItem("emailForSignIn", email);
    const result = await fetchWithContext("/firebase/user", {}, fetchParams);
    postDataLayer("free_trial_email_submitted", email);
    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message || "Something went wrong. Please try again.",
    });
  }
};

export const resendVerificationEmail = async () => {
  const fetchParams = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  };
  const emailId = localStorage.getItem("emailForSignIn");
  if (emailId) {
    try {
      const result = await fetchWithContext(
        `/firebase/user/resendVerificationLink?email=${encodeURIComponent(
          emailId
        )}`,
        {},
        fetchParams
      );
      return result;
    } catch (error: any) {
      const errorBody = await error.json();
      return Promise.reject({
        message:
          errorBody.message ||
          "Failed to send verification email. Please try again.",
      });
    }
  }
};

export const verifyUser = async ({ currentUser }: AuthProviderContext) => {
  const headers = defaultHeaders(currentUser?.accessToken);
  const fetchParams = { headers: headers };
  try {
    const result = await fetchWithContext(
      verifyUserStatusPath,
      {},
      fetchParams
    );

    return result;
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message || "Could not log in. Please try again later.",
    });
  }
};

export const requestMagicLink = async (
  _: AuthProviderContext,
  { email }: SendMagicLinkEvent
) => {
  const actionCodeSettings = {
    url: "https://cloud.orkes.io",
    handleCodeInApp: true,
  };

  if (auth && email) {
    try {
      const isNewUser = await isNewUserInFirebase(email);
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      if (isNewUser) {
        postDataLayer("free_trial_email_submitted", email);
        sendLinkedInEmailConfirmationEvent();
      }
      localStorage.setItem("emailForSignIn", email);
      return {
        text: "A link has been sent to your email.",
        severity: "success",
      };
    } catch (error: any) {
      const errorCode = error.code;

      const errorMessages: Record<string, string> = {
        "auth/invalid-email": "Please enter a valid email.",
        "auth/quota-exceeded": "Exceeded daily quota for email sign-in.",
      };

      return Promise.reject({
        message: errorMessages[errorCode] || error.message,
      });
    }
  }
};

export const loginWithEmailPassword = async (
  _: AuthProviderContext,
  { data }: LoginWithPasswordEvent
) => {
  const { email, password } = data;
  if (auth && email && password) {
    try {
      localStorage.setItem("emailForSignIn", email);
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result.user;
    } catch (error: any) {
      const tooManyRequests =
        "Too many sign-in attempts. Please wait and try again later.";
      const invalidCredentials =
        "Please check your username and password and try again.";
      const errorText =
        error.code === "auth/too-many-requests"
          ? tooManyRequests
          : invalidCredentials;

      return Promise.reject({
        message: errorText,
      });
    }
  }
};

export const triggerResetPasswordEmail = async (
  _context: AuthProviderContext,
  { email }: SendResetPasswordEvent
) => {
  if (email) {
    try {
      const result = await fetchWithContext(
        `/firebase/user/requestPasswordReset?email=${encodeURIComponent(
          email
        )}`,
        {},
        {
          method: "POST",
          headers: {
            Accept: `application/json`,
            "Content-Type": `application/json`,
          },
        }
      );
      return result;
    } catch (error: any) {
      const errorBody = await error.json();
      return Promise.reject({
        message:
          errorBody.message ||
          "Failed to send reset password email. Please try again.",
      });
    }
  }
};

export const invokeSignInWithEmailLink = async (
  _: AuthProviderContext,
  { email, pageUrl }: SignInWithEmailLinkEvent
) => {
  if (auth) {
    try {
      const result = await signInWithEmailLink(auth, email, pageUrl);
      return result.user;
    } catch (error: any) {
      const errorBody = await error.json();
      console.log("Error during sign-in with email link:", errorBody);
      return Promise.reject({
        message:
          errorBody.message || "Could not log in. Please try again later.",
      });
    }
  }
};

export const submitNewPasswordAndLogin = async (
  _: AuthProviderContext,
  { data }: SubmitNewPasswordEvent
) => {
  const { email, password, token } = data;
  const fetchParams = {
    headers: { Accept: `application/json`, "Content-Type": `application/json` },
    method: "POST",
    body: JSON.stringify({
      email,
      token,
      newPassword: password,
    }),
  };

  try {
    const result = await fetchWithContext(
      "/firebase/user/resetPassword",
      {},
      fetchParams
    );
    if (result) {
      const loginResponse = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return loginResponse.user;
    }
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message || "Something went wrong. Please try again.",
    });
  }
};

export const verifyEmail = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");
  const id = searchParams.get("id");

  if (email && id) {
    try {
      await fetchWithContext(
        `/firebase/user/verify/${email}?id=${id}`,
        {},
        { method: "GET" }
      );
      postDataLayer("free_trial_email_confirmed", email);
      sendLinkedInEmailConfirmationEvent();
    } catch (error: any) {
      const errorBody = await error.json();
      return Promise.reject({
        message: errorBody.message || "Something went wrong. Please try again.",
      });
    }
  }
};
