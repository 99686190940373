import "../css/ClusterListings.css";
import { Box, CircularProgress, Grid, Paper } from "@mui/material";
import { Helmet } from "react-helmet";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { BootstrapOutlineButton } from "components/buttons/CustomButtons";
import { Link, useSearchParams } from "react-router-dom";
import {
  CustomerEnvironment,
  EnvironmentTypeOption,
  environmentTypeOptions as environmentTypes,
  ProvisionRequest,
  VpcInfoResponse,
} from "utils/saastypes";
import LinearProgress from "../components/LinearProgress";
import {
  useActionWithPath,
  useCustomerRegions,
  useCustomerVPCIds,
} from "utils/query";
import {
  clusterNameExp,
  getComputeUnitIcon,
  getEnvironmentIcon,
  getValueForAutocomplete,
} from "utils/utils";
import ClusterField from "../components/ClusterField";
import AlertDialog from "../components/AlertDialog";
import StyledMainButton from "../components/buttons/StyledMainButton";
import { useNavigate } from "react-router";
import { SUB_DOMAIN_REGEX } from "constants/common";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";

function getInitialErrors(provisionRequest: ProvisionRequest) {
  const newErrors: any = {};
  if (
    undefined === provisionRequest.customerAccountId ||
    provisionRequest.customerAccountId?.trim() === ""
  ) {
    newErrors["customerAccountId"] = "Cloud provider account id cannot be null";
  }
  if (
    undefined === provisionRequest.clusterName ||
    !clusterNameExp.test(provisionRequest.clusterName)
  ) {
    newErrors["clusterName"] = "Cluster name is not valid";
  }
  if (
    undefined === provisionRequest.region ||
    provisionRequest.region?.trim() === ""
  ) {
    newErrors["region"] = "Region cannot be null";
  }
  return newErrors;
}

const validateDataForCft = (
  provisionRequest: ProvisionRequest,
  errors: any,
  setErrors: any
): boolean => {
  const newErrors = getInitialErrors(provisionRequest);
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const extendedValidation = (
  provisionRequest: ProvisionRequest,
  errors: any,
  setErrors: any,
  selectedDomain: any
): boolean => {
  const newErrors = getInitialErrors(provisionRequest);
  if (undefined === provisionRequest.vpcId || null === provisionRequest.vpcId) {
    newErrors["vpcId"] = "Invalid VPC id";
  }
  if (
    undefined === provisionRequest.azIds ||
    provisionRequest.azIds?.length === 0 ||
    provisionRequest.azIds?.length > 3
  ) {
    newErrors["azIds"] = "Invalid availability zone ids";
  }
  if (
    (!provisionRequest.subDomain || !provisionRequest.domainUrl) &&
    !selectedDomain?.fixedDomain
  ) {
    newErrors["subDomain"] = "Sub domain is required";
  } else if (!SUB_DOMAIN_REGEX.test(provisionRequest.subDomain)) {
    newErrors["subDomain"] =
      "Invalid sub domain. Allowed [a-z0-9] separated by hyphens";
  }

  if (
    undefined === provisionRequest.domainUrl ||
    provisionRequest.domainUrl?.length <= 0
  ) {
    newErrors["domainUrl"] = "Domain URL is required";
  }
  if (
    undefined === provisionRequest.ingressType ||
    null === provisionRequest.ingressType
  ) {
    newErrors["ingressType"] = "Ingress Type is required";
  }
  if (
    undefined === provisionRequest.computeUnits ||
    null === provisionRequest.computeUnits
  ) {
    newErrors["computeUnits"] = "Compute units is required";
  }
  if (
    undefined === provisionRequest.environmentType ||
    null === provisionRequest.environmentType
  ) {
    newErrors["environmentType"] = "The Environment Type is required";
  }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const ingressTypes = [
  { id: "INTERNAL", title: "VPC Only - Access via VPN or Peering" },
  { id: "EXTERNAL", title: "Internet - Access using Credentials" },
];

const computeUnits = [
  { id: "1", title: "Small - 100s - 1000s of Workflows / Day" },
  { id: "2", title: "Medium - 1000s - 10,000s of Workflows / Day" },
  { id: "3", title: "Large - 100,000s - 1,000,000s of Workflows / Day" },
  { id: "4", title: "Extra Large - > 1,000,000s of Workflows / Day" },
  {
    id: "10",
    title: "Optimized for High Performance",
  },
];

const authenticationTypes = [
  { id: "true", title: "Yes" },
  { id: "false", title: "No" },
];

type Route53Option = {
  id: string;
  title: string;
  fixedDomain: boolean;
};

export default function CreateNewEnvironmentCustomClusterNextStep() {
  const { authService } = useAuth();
  const [{ isOrkesUser }] = useAuthMachine(authService);

  const [searchParams] = useSearchParams();
  const [provisionRequest, setProvisionRequest] = useState<ProvisionRequest>(
    new ProvisionRequest(
      searchParams.get("cloudProvider") as string,
      searchParams.get("accountId") as string,
      searchParams.get("clusterName") as string,
      1,
      environmentTypes[0].id // e.g. PRODUCTION
    )
  );
  const [openConfirmCreate, setOpenConfirmCreate] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [vpcInfo, setVpcInfo] = useState<VpcInfoResponse | null>(null);
  const [vpcValidReason, setVpcValidReason] = useState<string>("");
  const [azCount, setAzCount] = useState<number>(0);
  const [isAzFocused, setIsAzFocused] = useState<boolean>(false);
  const [subDomain, setSubDomain] = useState<string>("");
  const [selectedDomain, setSelectedDomain] = useState<any>(null);
  const [environmentType, setEnvironmentType] = useState<EnvironmentTypeOption>(
    environmentTypes[0] // keep it in sync with environmentTypeOptions[0].id up above!
  );

  useEffect(() => {
    // this will sync the provRequest with the selected env
    setProvisionRequest((prevState) => ({
      ...prevState,
      environmentType: environmentType?.id,
    }));
  }, [environmentType]);

  const environmentTypeOptions = useMemo(() => {
    const result = [...environmentTypes];

    if (!isOrkesUser) {
      result.pop();
    }

    return result;
  }, [isOrkesUser]);

  const availabilityZones = useMemo(() => {
    if (vpcInfo) {
      return vpcInfo.availabilityZones.map((zone) => ({
        id: zone,
        title: zone,
      }));
    }
  }, [vpcInfo]);

  const route53Zones = useMemo(() => {
    if (vpcInfo) {
      return vpcInfo.route53?.reduce((result: Route53Option[], zone: any) => {
        let dnWoDot = zone.domainName;

        if (dnWoDot.endsWith(".")) {
          dnWoDot = zone.domainName.substring(0, zone.domainName.length - 1); // Remove the "." at the end
        }

        result.push({
          id: zone.domainName,
          title: `${dnWoDot} - ${zone.fixedDomain ? "Orkes" : "Customer"}`,
          fixedDomain: zone.fixedDomain,
        });

        return result;
      }, []);
    }

    return [];
  }, [vpcInfo]);

  const { data: regions, isFetching: regionsFetching } = useCustomerRegions(
    provisionRequest.clusterName,
    provisionRequest.customerAccountId
  );
  const { data: vpcIds, isFetching: vpcIdsFetching } = useCustomerVPCIds(
    provisionRequest.clusterName,
    provisionRequest.customerAccountId,
    provisionRequest.region
  );

  const handleChangeData = (
    e: any,
    value?: any,
    reason?: string,
    id?: string,
    domain?: string
  ) => {
    if (id) {
      const additionalValues: any = {};
      if (id === "region") {
        additionalValues["vpcId"] = null;
        additionalValues["prevVpcId"] = provisionRequest["vpcId"];
        setVpcInfo(null);
        setVpcValidReason("");
      }
      if (id === "vpcId") {
        additionalValues["prevVpcId"] = provisionRequest["vpcId"];
        setVpcInfo(null);

        if (additionalValues["prevVpcId"] !== value) {
          setVpcValidReason("");
        }
      }
      if (id === "enableAuthentication") {
        additionalValues["enableAuthentication"] = value === "true";
      }

      let newPR = {
        ...provisionRequest,
        ...additionalValues,
        ...(domain ? { domainUrl: domain } : {}),
        [id]: value,
      };
      setProvisionRequest(newPR);
      if (id === "azIds") {
        if (Array.isArray(value)) {
          if (value.length > 3) {
            setErrors((prevErrors: any) => ({
              ...prevErrors,
              azIds: "Too many AZs",
            }));
          } else if (errors["azIds"] !== undefined) {
            setErrors((prevErrors: any) => ({
              ...prevErrors,
              azIds: undefined,
            }));
          }
          setAzCount(value.length);
        }
      } else {
        if (value !== null && value !== undefined && errors[id] !== undefined) {
          setErrors((prevErrors: any) => ({
            ...prevErrors,
            [id]: undefined,
          }));
        }
      }
    } else {
      setProvisionRequest({
        ...provisionRequest,
        [e.target.name]: e.target.value,
        ...(domain ? { domainUrl: domain } : {}),
      });
    }
  };

  // TODO this is messy. and it will become a bug report for sure. Sorry.
  useEffect(() => {
    if (
      provisionRequest.prevVpcId !== provisionRequest.vpcId &&
      vpcInfo === null &&
      provisionRequest.vpcId !== null
    ) {
      validateVpc(provisionRequest.vpcId);
    }
    // eslint-disable-next-line
  }, [provisionRequest, vpcInfo]);

  const commonErrorText =
    "Please use a newly created or empty VPC with a private IP CIDR block from /16 to /23.";
  const validateVpcAction = useActionWithPath({
    onSuccess: (data: VpcInfoResponse) => {
      setVpcInfo(data);
      if (data.vpcCIDR) {
        try {
          let str = data.vpcCIDR;
          let mask = parseInt(str.substring(str.lastIndexOf("/") + 1));
          if (mask < 16 || mask > 23) {
            setVpcValidReason(
              `Require a CIDR mask from 16 to 23, VPC has a CIDR mask of ${mask} - CIDR - ${data.vpcCIDR}. ${commonErrorText}`
            );
            return;
          } else {
            setProvisionRequest({
              ...provisionRequest,
            });
          }
        } catch (e) {
          setVpcValidReason(`Unable to parse CIDR info. ${commonErrorText}`);
          return;
        }
      } else {
        setVpcValidReason(`Unable to read CIDR info. ${commonErrorText}`);
        return;
      }
      setVpcValidReason("");
      console.log("VPC info fetched");
    },
    onError: async (response: any) => {
      response.json().then((json: any) => {
        setVpcValidReason(`${json.message}.`);
      });
      console.error("VPC error: ", response);
    },
  });

  const navigate = useNavigate();

  const provisionEnvironmentAction = useActionWithPath({
    onSuccess: (data: CustomerEnvironment) => {
      setTimeout(() => {
        navigate(
          `/home/clusterprogess?clusterName=${data.name}&showSucess=true`
        );
      }, 3000);
    },
  });

  function triggerProvisioningFlow(confirmed: boolean) {
    if (
      extendedValidation(provisionRequest, errors, setErrors, selectedDomain) &&
      vpcValidReason === ""
    ) {
      if (confirmed) {
        setOpenConfirmCreate(false);
        // We need to enhance the form to include a sub domain name if required.
        const clusNameLc = provisionRequest.clusterName?.toLocaleLowerCase();
        const newProvReq = {
          ...provisionRequest,
          clusterName: clusNameLc,
          domainUrl: `${provisionRequest.domainUrl}`,
          conductorUIURL: `${provisionRequest.domainUrl}`,
          vpcCidr: vpcInfo?.vpcCIDR,
          subnets: vpcInfo?.vpcSubnets,
          routeTables: vpcInfo?.routeTables,
          rdsEnabled: true,
        };
        // @ts-ignore
        provisionEnvironmentAction.mutate({
          method: "post",
          path: `/provisionEnvironment`,
          body: JSON.stringify(newProvReq),
          successMessage: `Cluster ${clusNameLc} has been scheduled for provisioning. Redirecting you to monitor provisioning.`,
        });
      } else {
        setOpenConfirmCreate(true);
      }
    }
  }

  function validateVpc(vpcId: string | null | undefined) {
    if (validateDataForCft(provisionRequest, errors, setErrors) && vpcId) {
      const bodyObj = {
        clusterName: provisionRequest.clusterName,
        customerAccountId: provisionRequest.customerAccountId,
        vpcId: vpcId,
        region: provisionRequest.region,
      };
      // @ts-ignore
      validateVpcAction.mutate({
        method: "post",
        path: `/vpcInfo`,
        body: JSON.stringify(bodyObj),
      });
    }
  }

  const onChangeSubDomain = (e: any, reason: any) => {
    const { value } = e.target;
    setSubDomain(value);
    if (value && selectedDomain && !selectedDomain.fixedDomain) {
      let slicedValue = `${value}.${selectedDomain?.id?.slice(
        0,
        selectedDomain?.id?.length
      )}`;
      setProvisionRequest((prevState) => ({
        ...prevState,
        domainUrl: slicedValue,
      }));
      handleChangeData(e, value, reason, "subDomain", slicedValue);
    } else {
      handleChangeData(e, value, reason, "subDomain");
    }
  };

  const onChangeBaseDomain = (e: any, value: any, reason: any) => {
    let validValue = getValueForAutocomplete(value);

    if (value?.fixedDomain) {
      setSubDomain("");
    } else if (subDomain) {
      validValue = subDomain + "." + validValue?.slice(0, validValue?.length);
    }
    setSelectedDomain(value);
    handleChangeData(e, validValue, reason, "domainUrl");
  };

  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Create New Customer Cluster</title>
      </Helmet>
      {(regionsFetching ||
        vpcIdsFetching ||
        validateVpcAction.isLoading ||
        provisionEnvironmentAction.isLoading) && <LinearProgress />}
      <AlertDialog
        textTitle={""}
        textContent={
          "Please confirm if you want to proceed to provision a cluster based on the selected configuration."
        }
        open={openConfirmCreate}
        setOpen={setOpenConfirmCreate}
        handleConfirm={() => triggerProvisioningFlow(true)}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          borderBottom: "1px solid rgb(200,200,200, 0.5)",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "column",
            paddingLeft: 25,
            paddingRight: 0,
            paddingTop: 5,
          }}
        >
          <h2 style={{ marginBottom: 15 }}>
            Create Customer Cluster {searchParams.get("clusterName")} in Account{" "}
            {searchParams.get("accountId")}
          </h2>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "row",
            paddingRight: 20,
            paddingTop: 5,
          }}
        >
          <BootstrapOutlineButton
            color={"inherit"}
            style={{
              width: 100,
              fontSize: 15,
              lineHeight: "27px",
              marginRight: 10,
              fontFamily: "Lexend, sans-serif",
              fontWeight: 800,
              textAlign: "center",
              borderRadius: 5,
            }}
            //@ts-ignore
            component={Link}
            to={"/home/clusters"}
          >
            Cancel
          </BootstrapOutlineButton>
        </Box>
      </Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexDirection: "column",
          width: "100%",
          minHeight: "calc(100vh - 137px)",
          maxHeight: "calc(100vh - 137px)",
          overflowY: "auto",
        }}
      >
        <Paper
          style={{
            marginTop: 30,
            marginLeft: 30,
            marginRight: 30,
            padding: 20,
            fontFamily: "Lexend, sans-serif",
            minWidth: 400,
            maxWidth: 900,
          }}
        >
          <p
            style={{
              fontSize: 18,
              marginTop: 0,
              fontFamily: "Lexend, sans-serif",
              fontWeight: 600,
            }}
          >
            Customer Cluster in your Cloud Account
          </p>
          <Grid container spacing={2} style={{ width: 600 }}>
            <ClusterField
              id={"cloudProvider"}
              label={"Cloud Provider"}
              labelValue={provisionRequest.cloudProvider}
              type={"label"}
            />
            <ClusterField
              id={"customerAccountId"}
              label={"Account Id"}
              type={"label"}
              labelValue={provisionRequest.customerAccountId}
            />
            <ClusterField
              id={"clusterName"}
              label={"Cluster Name"}
              labelValue={provisionRequest.clusterName}
              type={"label"}
            />
          </Grid>
          <p className={"headerValue"} style={{ paddingTop: 20, fontSize: 14 }}>
            Now that we have authorized your account for this cluster{" "}
            {provisionRequest.clusterName}, we can proceed with the cluster
            provisioning.
          </p>
          <Grid container spacing={2} style={{ width: 600 }}>
            <ClusterField
              id={"region"}
              onChange={(e, value, reason) =>
                handleChangeData(
                  e,
                  getValueForAutocomplete(value),
                  reason,
                  "region"
                )
              }
              error={errors["region"] !== undefined}
              ddWidth={280}
              items={regions ? regions : []}
              label={"Region"}
              type={"select"}
              helperText={
                "Enter the region where you want to provision this cluster."
              }
            />
            <ClusterField
              id={"vpcId"}
              onChange={(e, value, reason) =>
                handleChangeData(
                  e,
                  getValueForAutocomplete(value),
                  reason,
                  "vpcId"
                )
              }
              ddWidth={280}
              error={errors["vpcId"] !== undefined}
              value={provisionRequest.vpcId}
              isOptionEqualToValue={(o: any, v: any) => {
                return o.id && o.id === v;
              }}
              getOptionLabel={(val: any) => {
                if (val.title) {
                  return val.title;
                }
                return val;
              }}
              items={vpcIds ? vpcIds : []}
              label={"VPC Id"}
              type={"select"}
              helperText={
                "Select a VPC (Available VPCs in your account will be loaded by selected region)"
              }
            />
          </Grid>

          {validateVpcAction.isLoading && (
            <Box
              className={"headerValue"}
              sx={{
                paddingTop: "20px",
                fontSize: "14px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box sx={{ height: 50 }}>
                <CircularProgress />
              </Box>
              <Box sx={{ paddingLeft: 2 }}>Validating VPC settings...</Box>
            </Box>
          )}
          {!validateVpcAction.isLoading && vpcValidReason !== "" && (
            <div
              className={"headerValue"}
              style={{
                paddingTop: 20,
                fontSize: 14,
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ color: "#e30508" }}>{vpcValidReason}</div>
            </div>
          )}
          {!validateVpcAction.isLoading &&
            vpcValidReason === "" &&
            vpcInfo !== null && (
              <>
                <div
                  className={"headerValue"}
                  style={{
                    paddingTop: 20,
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: 20,
                  }}
                >
                  <div style={{ color: "#03ad31" }}>
                    Great - We have validated this VPC and it meets the
                    requirements for provisioning this Conductor cluster.
                  </div>
                </div>

                <Grid container spacing={2} style={{ width: 600 }}>
                  <ClusterField
                    id={"azIds"}
                    onChange={(e, value, reason) =>
                      handleChangeData(
                        e,
                        getValueForAutocomplete(value),
                        reason,
                        "azIds"
                      )
                    }
                    onBlur={() => {
                      setIsAzFocused(false);
                    }}
                    onFocus={() => {
                      setIsAzFocused(true);
                    }}
                    error={errors["azIds"] !== undefined}
                    ddWidth={500}
                    disableCloseOnSelect={true}
                    items={availabilityZones}
                    label={"Preferred Availability Zones"}
                    type={"select"}
                    multiple={true}
                    helperText={`Let's pick your preferred availability zones. Max of 3.`}
                  />
                  {!isAzFocused && azCount > 0 && azCount < 3 && (
                    <div
                      style={{
                        color: "#f0ad4e",
                        fontSize: 14,
                        padding: 16,
                        paddingRight: 0,
                      }}
                    >
                      We recommend choosing 3 availability zones to ensure the
                      highest possible reliability.
                    </div>
                  )}

                  <Grid container pl={2}>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Lexend, sans-serif",
                        fontWeight: 600,
                        pt: 1.5,
                      }}
                    >
                      {"Domain Url"}
                    </Grid>
                    <Grid item xs={4} pl={0.6}>
                      <ClusterField
                        id={"subDomain"}
                        textSuffix={`.`}
                        value={subDomain}
                        onChange={onChangeSubDomain}
                        error={
                          selectedDomain &&
                          !selectedDomain?.fixedDomain &&
                          (!subDomain || errors.subDomain)
                        }
                        ddWidth={190}
                        label={""}
                        type={"text"}
                        helperText={
                          !selectedDomain?.fixedDomain && errors.subDomain
                            ? errors.subDomain
                            : selectedDomain?.fixedDomain
                            ? "For Orkes domains, sub domains will be auto assigned"
                            : "Sub domain"
                        }
                        disabled={selectedDomain?.fixedDomain}
                        placeholder={
                          selectedDomain?.fixedDomain ? "Auto Assigned" : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <ClusterField
                        id={"domainUrl "}
                        value={selectedDomain}
                        onChange={onChangeBaseDomain}
                        error={errors["domainUrl"] !== undefined}
                        ddWidth={310}
                        items={route53Zones}
                        label={""}
                        type={"select"}
                        multiple={false}
                        helperText={
                          "Access to Conductor will be configured in this selected domain"
                        }
                      />
                    </Grid>
                  </Grid>

                  <ClusterField
                    id={"ingressType"}
                    onChange={(e, value, reason) =>
                      handleChangeData(
                        e,
                        getValueForAutocomplete(value),
                        reason,
                        "ingressType"
                      )
                    }
                    error={errors["ingressType"] !== undefined}
                    ddWidth={400}
                    items={ingressTypes}
                    label={"Ingress Type"}
                    type={"select"}
                    multiple={false}
                    helperText={
                      "Pick access to Conductor app and UI - via Internet or via VPC"
                    }
                  />
                  <ClusterField
                    id={"computeUnits"}
                    onChange={(e, value, reason) =>
                      handleChangeData(
                        e,
                        getValueForAutocomplete(value),
                        reason,
                        "computeUnits"
                      )
                    }
                    error={errors["computeUnits"] !== undefined}
                    ddWidth={500}
                    defaultValue={computeUnits[0]}
                    items={computeUnits}
                    label={"Cluster Capacity"}
                    type={"select"}
                    multiple={false}
                    helperText={
                      "Pick a suitable capacity for your cluster. This can be adjusted later."
                    }
                    showDropdownIcons={true}
                    iconHandler={getComputeUnitIcon}
                  />
                  <ClusterField
                    id={"environmentType"}
                    onChange={(
                      e: ChangeEvent<HTMLInputElement>,
                      value: EnvironmentTypeOption
                    ) => {
                      setEnvironmentType(value);
                    }}
                    error={errors["environmentType"] !== undefined}
                    ddWidth={300}
                    items={environmentTypeOptions}
                    value={environmentType}
                    label={"Environment Type"}
                    type={"select"}
                    helperText={
                      "Tag your cluster's environment type. It is helpful for distinguishing between multiple instances."
                    }
                    showDropdownIcons={true}
                    iconHandler={getEnvironmentIcon}
                  />
                  <ClusterField
                    id={"enableAuthentication"}
                    onChange={(e, value, reason) =>
                      handleChangeData(
                        e,
                        getValueForAutocomplete(value),
                        reason,
                        "enableAuthentication"
                      )
                    }
                    error={errors["enableAuthentication"] !== undefined}
                    ddWidth={300}
                    defaultValue={authenticationTypes[0]}
                    items={authenticationTypes}
                    label={"Enable authentication"}
                    type={"select"}
                    multiple={false}
                    helperText={
                      "Set this to Yes to protect access to your Conductor cluster. A default authorization configuration will be added and you can change this to your own OAuth provider."
                    }
                  />
                </Grid>
              </>
            )}

          <p className={"headerValue"} style={{ paddingTop: 20, fontSize: 14 }}>
            Once you have provided / selected all the required info, you can
            click the button below to start the cluster provisioning. Most
            provisioning flows will complete within an hour and we can monitor
            progress.
          </p>

          <StyledMainButton
            size="small"
            color={"inherit"}
            style={{
              width: 250,
              fontSize: 15,
              marginRight: 10,
              marginTop: 10,
            }}
            onClick={() => {
              triggerProvisioningFlow(false);
            }}
          >
            Create Provisioning Workflow
          </StyledMainButton>
        </Paper>
        <Box style={{ minHeight: 100 }}>
          <p />
        </Box>
      </Box>
    </>
  );
}
